<script setup>
  import { ref, computed, onMounted, onUnmounted } from 'vue';
  import FileUpload from 'primevue/fileupload';
  import { useToast } from 'primevue/usetoast';
  import axios from 'axios';
  import api from '../entrypoints/api.js';
  import { useI18n } from "vue-i18n";
  import { handleFileDownload } from "../entrypoints/utils.js";
  import { useStore } from 'vuex'

  const store = useStore();
  const toast = useToast();
  const { t } = useI18n();

  const participantForm = ref({
    first_name: null,
    last_name: null,
    elo_standard: null,
    elo_rapid: null,
    elo_blitz: null,
    id_ffe: null,
    email: null,
    checkin: false,
  })
  const submitType = ref(null)

  const fileUpload = ref(null);
  const pendingParticipantsUpload = ref(null)

  const registrationModalVisible = ref(false)
  const importFileModalVisible = ref(false)

  const registrationMenu = ref();
  const registrationsItems = ref([
    {
      label: t('registrationMenu.registrationSectionTitle'),
      items: [
        {
          label: t('registrationMenu.newParticipant'),
          icon: 'pi pi-plus text-blue-300',
          command: () => { registrationModalVisible.value = true; },
        },
        {
          label: t('registrationMenu.downloadPlayers'),
          icon: 'pi pi-download text-blue-300',
          command: () => { importFileModalVisible.value = true; },
        },
      ],
    },
  ]);

  async function addNewParticipant() {
    if (selectedRegistrationOption.value.key === 'account' || selectedRegistrationOption.value.key === 'ffe') {
      participantForm.value.first_name = null;
      participantForm.value.last_name = null;
      participantForm.value.elo_standard = null;
      participantForm.value.elo_rapid = null;
      participantForm.value.elo_blitz = null;
      if (selectedRegistrationOption.value.key === 'account') {
        participantForm.value.id_ffe = null;
      }
    } else if (selectedRegistrationOption.value.key === 'custom') {
      participantForm.value.id_ffe = null;
      participantForm.value.elo_blitz = participantForm.value.elo_rapid = participantForm.value.elo_standard;
    }
    const response = await api.createParticipant(store.getters.getTournament.id, participantForm.value);

    if (response.data.value) {
      if (submitType.value == "addAndClose") {
        registrationModalVisible.value = false;
      }

      toast.add({ severity: 'success', summary: `Player ${response.data.value.first_name} ${response.data.value.last_name} added`, life: 3000 });

      participantForm.value.first_name = '';
      participantForm.value.last_name = '';
      participantForm.value.elo_standard = participantForm.value.elo_blitz = participantForm.value.elo_rapid = '';
      participantForm.value.id_ffe = '';
      participantForm.value.email = '';
    }
    if (response.error.value) {
      toast.add({ severity: 'error', summary: response.error.value.response.data.message, life: 3000 });
    }
  }

  async function uploadFile() {
    const files = fileUpload.value.files;

    if (files.length === 0) {
      console.error('No file selected.');
      return;
    }

    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('tournament_id', store.getters.getTournament.id)

    pendingParticipantsUpload.value = true;
    axios.post('/api/upload_file', formData)
      .then(response => {
        importFileModalVisible.value = false;
        toast.add({ severity: "success", summary: "Participants successfully uploaded", life: 3000 })
        pendingParticipantsUpload.value = false;
      })
      .catch(error => {
        console.error('Error uploading file:', error);
        toast.add({ severity: 'error', summary: error.response.data.message });
        pendingParticipantsUpload.value = false;
      });
  }

  async function downloadTemplate() {
    try {
      const response = await axios.get('/api/download_players_list_template', {
        responseType: "blob",
      });

      handleFileDownload(response, 'players_template.csv')
    } catch (error) {
      toast.add({ severity: 'error', summary: 'Error downloading template', life: 3000 });
    }
  }

  const toggleRegistrationMenu = (event) => {
    registrationMenu.value.toggle(event);
  }

  const registrationOptions = computed(() => {
    const tabsList = [];

    if (store.getters.getTournament.federation === 'friendly') {
      tabsList.push({ name: t('account'), value: 1, key: 'account' });
      tabsList.push({ name: t('registrationModal.custom'), value: 2, key: 'custom' });
    }
    tabsList.push({ name: 'FFE', value: 3, key: 'ffe' });

    return tabsList
  });
  const selectedRegistrationOption = ref(registrationOptions.value[0]);

  const handleEscape = (event) => {
    if (event.key === 'Escape') {
      registrationModalVisible.value = false;
      importFileModalVisible.value = false;
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', handleEscape);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleEscape);
  });
</script>

<template>
  <div v-if="store.getters.getTournament.status != 'over'"
  id="dropdown-new-registration">
    <button type="button" class="primary-btn flex gap-2 items-center min-h-11" @click="toggleRegistrationMenu">
      <i class="pi pi-plus text-lg align-middle"></i>
      <p class="hidden md:block">{{ $t('registrationMenu.add') }}</p>
    </button>
    <Menu ref="registrationMenu" id="registration_menu" :model="registrationsItems" :popup="true" />

    <div @click="registrationModalVisible = false" v-show="registrationModalVisible || importFileModalVisible" class="fixed inset-0 bg-black bg-opacity-50 z-40"></div>

    <!-- Main modal -->
    <div v-show="registrationModalVisible"
    id="registration-modal" tabindex="-1"
    class="fixed inset-0 z-50 flex items-center justify-center">
      <div class="relative p-4 mx-auto w-full max-w-md max-h-full">
        <!-- Modal content -->
        <div class="relative bg-dark-greyblue rounded-lg shadow">
          <!-- Modal header -->
          <div class="flex items-center justify-between p-4 md:p-5 mb-3 border-b rounded-t dark:border-gray-600">
            <h3 class="text-lg font-semibold text-white" v-t="'modalNewParticipantTitle'"></h3>
            <button type="button" class="transition duration-200 text-gray-200 hover:bg-danger hover:text-gray-200 rounded-lg w-8 h-8 ms-auto inline-flex justify-center items-center hover:text-red"
            data-modal-toggle="crud-modal"
            @click="registrationModalVisible = false">

              <div class="text-2xl">
                <i class="fa-solid fa-xmark"></i>
              </div>
            </button>
          </div>

          <div class="card flex justify-center">
            <SelectButton v-model="selectedRegistrationOption" :options="registrationOptions" optionLabel="name" :allowEmpty="false" />
          </div>

          <!-- Modal body -->
          <form @submit.prevent="addNewParticipant"
          class="p-4">
            <div class="mb-10">
              <div class="flex flex-col gap-3">
                <div v-show="store.getters.getTournament.federation !== 'ffe' && selectedRegistrationOption.key === 'custom'"
                class="col-span-2">
                  <label for="first-name" class="block mb-1 text-sm font-medium text-light-grey">{{ $t('firstName') }} *</label>
                  <input v-model="participantForm.first_name" type="text" name="first-name" id="first-name" class="bg-gray-600 border placeholder-gray-400 text-light-grey text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" :placeholder="$t('firstNameExample')" :required="selectedRegistrationOption.key === 'custom'">
                </div>
                <div v-show="store.getters.getTournament.federation !== 'ffe' && selectedRegistrationOption.key === 'custom'"
                class="col-span-2">
                  <label for="last-name" class="block mb-1 text-sm font-medium text-light-grey">{{ $t('lastName') }}</label>
                  <input v-model="participantForm.last_name" type="text" name="last-name" id="last-name" class="bg-gray-600 border placeholder-gray-400 text-light-grey text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" :placeholder="$t('firstNameExample')">
                </div>
                <div v-show="store.getters.getTournament.federation !== 'ffe' && selectedRegistrationOption.key === 'custom'"
                class="col-span-2">
                  <label for="rating" class="block mb-1 text-sm font-medium text-light-grey">{{ $t('rating') }}</label>
                  <input v-model="participantForm.elo_standard" type="number" name="rating" id="rating" class="bg-gray-600 border placeholder-gray-400 text-light-grey text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="1785">
                </div>
                <div v-show="selectedRegistrationOption.key === 'ffe'"
                class="col-span-2">
                  <label for="ffe-id" class="block mb-1 text-sm font-medium text-light-grey">FFE ID *</label>
                  <input v-model="participantForm.id_ffe" type="text" name="ffe-id" id="ffe-id" class="bg-gray-600 border placeholder-gray-400 text-light-grey text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="W55755" :required="selectedRegistrationOption.key === 'ffe'">
                </div>
                <div class="col-span-2">
                  <label for="email" class="block mb-1 text-sm font-medium text-light-grey">{{ $t('email') }} {{ selectedRegistrationOption.key === 'account' ? "*" : "" }}</label>
                  <input v-model="participantForm.email" type="email" name="email" id="email" class="bg-gray-600 border placeholder-gray-400 text-light-grey text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" :placeholder="t('emailExample')" :required="selectedRegistrationOption.key === 'account'">
                </div>
                <div class="flex gap-2 mt-2 items-center">
                  <Checkbox v-model="participantForm.checkin" inputId="default-activation" binary />
                  <label for="default-activation" class="block text-sm font-medium text-light-grey">{{ $t('adminActions.check') }}</label>
                </div>
              </div>
            </div>

            <div class="flex justify-between w-full text-center">
              <button @click="submitType = 'addPlayer'" type="submit" class="flex items-center primary-btn">
                <i class="pi pi-plus mr-3"></i>
                {{ $t('modalNewParticipantTitle') }}
              </button>
              <button @click="submitType = 'addAndClose'" type="submit" class="primary-btn">
                {{ $t('confirmBtn') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>


    <div v-show="importFileModalVisible" tabindex="-1"
    class="fixed inset-0 z-50 flex items-center justify-center">
      <div class="relative mx-auto p-4 w-full max-w-md max-h-full">
        <!-- Modal content -->
        <div class="relative bg-dark-greyblue rounded-lg shadow">
          <!-- Modal header -->
          <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 class="text-lg font-semibold text-light-grey m-0">{{ $t('registrationModal.title') }}</h3>
            <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg w-8 h-8 ms-auto inline-flex justify-center items-center hover:text-red"
            data-modal-toggle="crud-modal"
            @click="importFileModalVisible = false">

              <div class="text-2xl">
                <i class="fa-solid fa-xmark"></i>
              </div>
            </button>
          </div>
          <!-- Modal body -->
          <div class="p-4 md:p-5">
            <div class="text-center">
              <h1 class="text-lg mb-3 font-bold">{{ $t('registrationModal.addPlayersFromFileTitle') }}</h1>
              <FileUpload class="mb-3" mode="basic" name="demo[]" url="/api/upload_file" accept=".csv" :maxFileSize="1000000" :chooseLabel="$t('browse')" ref="fileUpload" />
              <button v-show="!pendingParticipantsUpload" class="primary-btn mb-5" @click="uploadFile">{{ $t('confirmBtn') }}</button>
              <ProgressSpinner v-show="pendingParticipantsUpload" />
              <div class="flex-auto items-center text-lime-300 mb-1">
                <i class="fa-solid fa-file-import text-lg mr-2"></i>
                <button class="text-md underline underline-offset-1" @click="downloadTemplate" v-t="'registrationModal.templateDownload'"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
