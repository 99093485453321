<script setup>
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import axios from 'axios';
  import { useToast } from 'primevue/usetoast';
  import { useConfirm } from 'primevue/useconfirm';
  import { useI18n } from 'vue-i18n';
  import api from '../entrypoints/api.js';
  import { handleFileDownload, isRoundMissingResults } from '../entrypoints/utils.js';

  import TournamentForm from './TournamentForm.vue';

  const router = useRouter();
  const store = useStore();
  const toast = useToast();
  const confirm = useConfirm();
  const { t } = useI18n();

  const tournamentId = router.currentRoute.value.params.id
  const tournament = ref({
    title: null,
    federation: null,
  });
  const newRefereeEmail = ref(null);
  const referees = ref([]);
  const accessToken = ref(null);
  const pendingPapiDownload = ref(false);
  const pendingTrfDownload = ref(false);

  async function fetchTournamentData() {
    if (!store.getters.getTournament) {
      const response = await api.getTournament(tournamentId);

      if (response.data.value.tournament) {
        store.dispatch("updateTournamentContext", response.data.value.tournament)
      } else {
        console.error(response.error.value.message);
      }
    }
    tournament.value.title = store.getters.getTournament.title
    tournament.value.federation = store.getters.getTournament.federation
    referees.value = store.getters.getTournament.referees;
  }

  async function createReferee() {
    axios.post(`${window.location.origin}/api/referees`, {
      tournament_id: tournamentId,
      email: newRefereeEmail.value,
    }).then(response => {
      referees.value.push(response.data);
      newRefereeEmail.value = null;
      toast.add({ severity: 'success', summary: 'Referee successfully added', life: 3000 });
    }).catch(error => {
      console.error(error);
      toast.add({ severity: 'error', summary: error.response.data.message, life: 3000 });
    })
  }

  function confirmDeleteReferee(event, referee) {
    confirm.require({
      target: event.currentTarget,
      message: t('confirm.deleteRefereeMessage', {firstName: referee.user.first_name, lastName: referee.user.last_name}),
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteReferee(referee),
      group: 'popup',
    });
  }

  async function deleteReferee(refereeToDelete) {
    axios.delete(`${window.location.origin}/api/referees/${refereeToDelete.id}`)
    .then(response => {
      referees.value = referees.value.filter((referee) => { return referee.id !== refereeToDelete.id })
      toast.add({ severity: 'success', summary: 'Referee successfully deleted', life: 3000 });
    })
    .catch(error => {
      toast.add({ severity: 'error', summary: 'Error deleting referee', detail: error.message, life: 3000 });
    })
  }

  async function showAccessToken() {
    axios.get(`/api/tournaments/${tournamentId}/access_token`)
    .then(response => {
      accessToken.value = response.data.access_token;
      if(accessToken.value == null)
        generateAccessToken()
    })
    .catch(error => {
      toast.add({ severity: 'error', summary: error.response.data.message, life: 3000 });
    })
  }

  async function generateAccessToken() {
    axios.get(`/api/tournaments/${tournamentId}/new_access_token`)
    .then(response => {
      accessToken.value = response.data.access_token;
    })
    .catch(error => {
      toast.add({ severity: 'error', summary: error.response.data.message, life: 3000 });
    })
  }

  async function deleteAccessToken() {
    axios.delete(`/api/tournaments/${tournamentId}/access_token`)
    .then(response => {
      accessToken.value = null;
    })
    .catch(error => {
      toast.add({ severity: 'error', summary: error.response.data.message, life: 3000 });
    })
  }

  async function updateRefereeRights(updatedReferee) {
    axios.put(`/api/referees/${updatedReferee.id}`, {
      activation_rights: updatedReferee.activation_rights,
      player_rights: updatedReferee.player_rights,
      round_rights: updatedReferee.round_rights,
    }).then(response => {
      const refereeIndex = referees.value.findIndex((referee) => referee.id == response.data.id)
      referees.value[refereeIndex] = response.data
    }).catch(error => {
      toast.add({ severity: 'error', summary: error.response.data.message, life: 3000 });
    })
  }

  function toggleRefereeActivationRights(refereeToUpdate) {
    const refereeCopy = {...refereeToUpdate};
    refereeCopy.activation_rights = !refereeCopy.activation_rights
    updateRefereeRights(refereeCopy)
  }

  function toggleRefereePlayerRights(refereeToUpdate) {
    const refereeCopy = {...refereeToUpdate};
    refereeCopy.player_rights = !refereeCopy.player_rights
    updateRefereeRights(refereeCopy)
  }

  function toggleRefereeRoundRights(refereeToUpdate) {
    const refereeCopy = {...refereeToUpdate};
    refereeCopy.round_rights = !refereeCopy.round_rights
    updateRefereeRights(refereeCopy)
  }

  function copyParticipantsEmail() {
    axios.get(`/api/tournaments/${tournamentId}/participants`)
    .then((response) => {
      navigator.clipboard.writeText(response.data.participants_email.filter(str => str !== null && str !== undefined).join('; '))
      toast.add({ severity: "success", summary: "Players emails succesfully copied", life: 3000 })
    })
    .catch((error) => {
      toast.add({ severity: "error", summary: error.response?.data?.message || t('errors.unexpectedError'), life: 3000 })
    })
  }

  function copyAccessToken() {
    navigator.clipboard.writeText(accessToken.value);
    toast.add({ severity: "success", summary: "Access token copied to clip board", life: 3000 });
  }

  async function downloadTournamentTrfFile() {
    pendingTrfDownload.value = true;

    try {
      const response = await axios.get(`/api/tournaments/${tournamentId}/download_trf`, {
        responseType: "blob",
        headers: {
          'Accept': 'text/plain',
        }
      });

      handleFileDownload(response, `${tournament.value.title}.trf`);
    } catch (error) {
      toast.add({ severity: 'error', summary: 'Error downloading file', life: 3000 });
    } finally {
      pendingTrfDownload.value = false;
    }
  }

  async function downloadTournamentPlayers() {
    try {
      const response = await axios.get(`/api/tournaments/${tournamentId}/download_players`, {
        responseType: "blob",
        headers: {
          'Accept': 'text/csv',
        }
      });

      handleFileDownload(response, `${tournament.value.title}_players.csv`);
    } catch (error) {
      toast.add({ severity: 'error', summary: 'Error downloading players', life: 3000 });
    }
  }

  async function downloadTournamentRounds() {
    try {
      const response = await axios.get(`/api/tournaments/${tournamentId}/download_rounds`, {
        responseType: "blob",
        headers: {
          'Accept': 'text/csv',
        }
      });

      handleFileDownload(response, `${tournament.value.title}_rounds.csv`);
    } catch (error) {
      toast.add({ severity: 'error', summary: 'Error downloading rounds', life: 3000 });
    }
  }

  async function downloadPapi() {
    try {
      pendingPapiDownload.value = true;
      const response = await axios.get(`/api/tournaments/${tournamentId}/download_papi`, {
        responseType: "blob",
        headers: {
          'Accept': 'application/x-msaccess',
        }
      });
      pendingPapiDownload.value = false;

      handleFileDownload(response, `${tournament.value.title}.papi`);
    } catch (error) {
      pendingPapiDownload.value = false;
      toast.add({ severity: 'error', summary: 'An error occured on file download', life: 3000 });
    }
  }

  async function downloadPapiExtension() {
    try {
      const response = await axios.get(`/api/papi/download_extension`, {
        responseType: "blob",
        headers: {
          'Accept': 'application/octet-stream',
        }
      });

      handleFileDownload(response, `pawnarena_papi_extension.exe`);
    } catch (error) {
      toast.add({ severity: 'error', summary: 'An error occured on file download', life: 3000 });
    }
  }

  async function toggleTournamentRegistrations() {
    let response = null
    if (store.getters.getTournament.open_registrations) {
      response = await api.closeRegistrations(store.getters.getTournament.id);
    } else {
      response = await api.openRegistrations(store.getters.getTournament.id);
    }

    if (response.data.tournament) {
      store.dispatch("updateTournamentContext", response.data.tournament)
    } else {
      toast.add({ severity: "error", summary: "An unexpected error occured", life: 3000 });
    }
  }

  async function startTournament() {
    const response = await api.startTournament(store.getters.getTournament.id);

    if (response.data.tournament) {
      store.dispatch("updateTournamentContext", response.data.tournament)
    } else {
      toast.add({ severity: "error", summary: "An unexpected error occured", life: 3000 });
    }
  }

  async function endTournament() {
    const response = await api.endTournament(store.getters.getTournament.id);

    if (response.data.tournament) {
      store.dispatch("updateTournamentContext", response.data.tournament)
    } else {
      toast.add({ severity: "error", summary: "An unexpected error occured", life: 3000 });
    }
  }

  async function reopenTournament() {
    const response = await api.reopenTournament(store.getters.getTournament.id);

    if (response.data.tournament) {
      store.dispatch("updateTournamentContext", response.data.tournament)
    } else {
      toast.add({ severity: "error", summary: "An unexpected error occured", life: 3000 });
    }
  }

  const confirmEndTournament = () => {
    confirm.require({
      header: t('confirm.endTournamentHeader'),
      message: t('confirm.endTournamentMessage'),
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: t('confirm.cancel'),
      acceptLabel: t('confirm.endTournament'),
      accept: () => endTournament(),
      group: "dialog",
    })
  }

  const confirmReopenTournament = () => {
    confirm.require({
      header: t('confirm.reopenTournamentHeader'),
      message: t('confirm.reopenTournamentMessage'),
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: t('confirm.cancel'),
      acceptLabel: t('confirm.reopen'),
      accept: () => reopenTournament(),
      group: 'dialog',
    })
  }

  fetchTournamentData();
</script>

<template>
  <div class="flex min-h-full flex-col justify-center px-6 py-4 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-3xl">
      <RouterLink :to="`/tournaments/${tournamentId}`" >
        <button class="primary-btn mb-3" v-t="'nav.back'"></button>
      </RouterLink>
      <TabView>
        <TabPanel :header="t('tournament.form.globalInfos')">
          <h1 v-if="!tournament.title" class="mb-4">{{ $t('loading') }}...</h1>
          <h1 v-else class="mb-4 text-2xl font-bold">{{ tournament.title }}</h1>
          <TournamentForm :inNewTournament="false" />
        </TabPanel>

        <TabPanel :header="t('tournament.edit.stateTab')">
          <div v-if="store.getters.getTournament" class="flex flex-col gap-3">
            <div v-if="store.getters.getTournament.status == 'pending'">
              <h5 class="mb-2 text-lg" v-t="'tournament.edit.startMessage'"></h5>
              <button class="primary-btn disabled:opacity-40 disabled:pointer-events-none"
              :disabled="store.getters.getTournament.participants.length < 2"
              @click="startTournament"
              v-t="'tournament.edit.startBtn'">
              </button>
            </div>
            <div v-if="store.getters.getTournament.status == 'running'">
              <h5 class="mb-2" v-t="'tournament.edit.endMessage'"></h5>
              <button class="danger-btn disabled:opacity-50 disabled:pointer-events-none"
              :disabled="store.getters.getTournament.rounds.length === 0 || isRoundMissingResults(store.getters.getTournament.rounds[store.getters.getTournament.rounds.length - 1])"
              @click="confirmEndTournament"
              v-t="'tournament.edit.endBtn'">
              </button>
            </div>
            <div v-if="store.getters.getTournament.status == 'over'">
              <h5 class="mb-2" v-t="'tournament.edit.reopenMessage'"></h5>
              <button class="warning-btn"
              @click="confirmReopenTournament"
              v-t="'tournament.edit.reopenBtn'">
              </button>
            </div>

            <div v-if="store.getters.getTournament.status != 'over'">
              <h5 class="mb-2" v-t="'tournament.edit.openRegistrationsMessage'"></h5>
              <button class="flex items-center w-20 h-8 rounded-full border-2 shadow-lg bg-opacity-40"
              :class="store.getters.getTournament.open_registrations ? 'border-success bg-success justify-end' : 'border-light-grey bg-light-grey'"
              @click="toggleTournamentRegistrations">
                <div class="opacity-100 rounded-full h-5 w-5 mx-1"
                :class="store.getters.getTournament.open_registrations ? 'bg-success' : 'bg-light-grey'"></div>
              </button>
            </div>
          </div>
          <div v-else>{{ $t('loading') }}...</div>
        </TabPanel>

        <TabPanel :header="t('tournament.form.referees')">
          <form @submit.prevent="createReferee">
            <div class="flex flex-col sm:flex-row items-center gap-2 mb-4">
              <InputText id="email" type="email" v-model="newRefereeEmail" class="w-full" :placeholder="`${$t('email')}...`" />
              <button type="submit" class="primary-btn w-full sm:w-2/5" v-t="'tournament.form.newReferee'"></button>
            </div>
          </form>

          <div>
            <h1 class="text-xl">{{ $t('tournament.form.referees') }} - {{ referees.length }}</h1>
            <p v-if="referees.length === 0" class="text-center" v-t="'tournament.form.noReferee'"></p>

            <div v-for="referee in referees"
            :key="referee.id"
            class="flex flex-wrap md:flex-unwrap justify-between gap-3 mt-3 p-2 items-center rounded bg-deep-greyblue border border-gray-500 shadow-md">
              <div class="flex gap-3">
                <p class="text-lg">{{ referee.user.first_name }}</p>
                <p class="text-lg">{{ referee.user.last_name }}</p>
              </div>
              <p class="text-lg">{{ referee.user.email }}</p>
              <div class="flex gap-3">
                <div>
                  <button @click="toggleRefereeActivationRights(referee)"
                  :disabled="referee.player_rights"
                  :class="referee.activation_rights || referee.player_rights ? 'success-btn' : 'warning-btn'"
                  class="mr-1 disabled:opacity-40 disabled:pointer-events-none">
                    <i class="fa-solid fa-eye align-middle"></i>
                    <p class="ml-2 hidden md:inline-block align-middle" v-t="'tournament.form.activationsRights'"></p>
                  </button>
                  <button @click="toggleRefereePlayerRights(referee)"
                  :class="referee.player_rights ? 'success-btn' : 'warning-btn'"
                  class="mr-1">
                    <i class="fa-solid fa-user-group align-middle"></i>
                    <p class="ml-2 hidden md:inline-block align-middle" v-t="'tournament.form.playersRights'"></p>
                  </button>
                  <button @click="toggleRefereeRoundRights(referee)"
                  :class="referee.round_rights ? 'success-btn' : 'warning-btn'">
                    <i class="fa-solid fa-circle-notch text-lg align-middle"></i>
                    <p class="ml-2 hidden md:inline-block align-middle" v-t="'tournament.form.roundsRights'"></p>
                  </button>
                </div>
                <button @click="confirmDeleteReferee($event, referee)"
                class="danger-btn">
                  <i class="fa-solid fa-trash-can"></i>
                </button>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel :header="t('tournament.edit.downloadsTab')">
          <div class="flex flex-col gap-6">
            <div class="mb-4">
              <button @click="copyParticipantsEmail" class="secondary-btn flex gap-4 items-center">
                <i class="fa-regular fa-clipboard"></i>
                <h1 class="">{{ $t('tournament.edit.copyParticipantsEmail') }}</h1>
              </button>
            </div>
            <div class="mt-4 flex gap-4 items-center">
              <button @click="downloadPapi()" class="secondary-btn flex gap-4 items-center disabled:opacity-40 disabled:pointer-events-none" :disabled="pendingPapiDownload">
                <i class="fa-solid fa-download"></i>
                {{ $t('tournament.edit.downloadPapiFile') }} (v3.3.8)
              </button>
              <a class="underline hover:text-orange-300 ease-in-out duration-100" target="_blank" href="https://www.echecs.asso.fr/Actu.aspx?Ref=14287">{{ $t('download')}} Papi</a>
              <ProgressSpinner v-show="pendingPapiDownload" style="width: 40px; height: 40px" strokeWidth="5" />
            </div>
            <div class="flex gap-4 items-center">
              <button @click="downloadTournamentTrfFile" class="secondary-btn flex gap-4 items-center disabled:opacity-40 disabled:pointer-events-none" :disabled="pendingTrfDownload">
                <i class="fa-solid fa-download"></i>
                <h1 class="">{{ $t('tournament.edit.downloadTrf') }}</h1>
              </button>
              <ProgressSpinner v-show="pendingTrfDownload" style="width: 40px; height: 40px" strokeWidth="5" />
            </div>
            <div class="mt-4">
              <button @click="downloadTournamentPlayers" class="secondary-btn flex gap-4 items-center">
                <i class="fa-solid fa-download"></i>
                <h1 class="">{{ $t('tournament.edit.downloadPlayersAction') }}</h1>
              </button>
            </div>
            <div>
              <button @click="downloadTournamentRounds" class="secondary-btn flex gap-4 items-center">
                <i class="fa-solid fa-download"></i>
                <h1 class="">{{ $t('tournament.edit.downloadRoundsAction') }}</h1>
              </button>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>
