<script setup>
  import { ref, computed, onMounted } from 'vue';
  import { useRouter } from "vue-router";
  import { useToast } from "primevue/usetoast";
  import api from "../entrypoints/api.js";
  import axios from "axios";
  import { seconds2scale, scale2seconds } from '../entrypoints/utils.js';

  const toast = useToast();
  const router = useRouter();

  const props = defineProps(['isNewTournament'])

  const fieldErrors = ref({})
  const tournamentId = ref(null)
  const formData = ref({
    title: null,
    date: null,
    end_date: null,
    system: "swiss",
    federation: 'friendly',
    format: 'standard',
    tie_breaker_1: "buchholz",
    modifier_tie_breaker_1: 1,
    tie_breaker_2: "tournament_performance_rating_fide",
    modifier_tie_breaker_2: 1,
    tie_breaker_3: null,
    modifier_tie_breaker_3: 1,
    max_participants: null,
    description: null,
    max_rounds: null,
    address: "",
    time_initial: null,
    time_per_move: null,
    max_full_byes: null,
    max_half_byes: null,
  });
  const timeSelects = ref({
    initial: "m",
    perMove: "s",
  })
  const pairingSystemOptions = ["swiss", "accelerated_degressive", "haley", "haley_degressive"];
  const tieBreakerOptions = computed(() => {
    if (formData.value.federation === "ffe") {
      return ["buchholz", "buchholz_cut", "buchholz_median", "buchholz_sum", "sonneborn_berger", "progressive_score", "wins", "kashdan", "tournament_performance_rating_fide"];
    } else {
      return ["buchholz", "buchholz_cut", "buchholz_median", "buchholz_average", "sonneborn_berger", "progressive_score", "progressive_score_cut", "wins", "games_won", "black_played_games", "black_won_games", "kashdan", "average_rating_opponents", "average_rating_opponents_cut", "tournament_performance_rating_fide", "average_performance_rating_opponents_fide"];
    }
  });

  function tieBreakerCallback() {
    if (!formData.value.tie_breaker_1) {
      formData.value.tie_breaker_2 = null;
    }
    if (!formData.value.tie_breaker_2) {
      formData.value.tie_breaker_3 = null;
    }
  }

  function maxRoundsUpdateCallback() {
    for (let i = 1; i <= 3; i++) {
      if (!formData.value[`tie_breaker_${i}`] || !formData.value[`tie_breaker_${i}`].endsWith("_cut") && !formData.value[`tie_breaker_${i}`].endsWith("_median")) {
        if (formData.value.max_rounds >= 13) {
          formData.value[`modifier_tie_breaker_${i}`] = 3;
        } else if (formData.value.max_rounds >= 8) {
          formData.value[`modifier_tie_breaker_${i}`] = 2;
        } else {
          formData.value[`modifier_tie_breaker_${i}`] = 1;
        }
      }
    }
  }

  async function handleFormValidation() {
    for (let i = 1; i <= 3; i++) {
      if (!formData.value[`tie_breaker_${i}`] || !formData.value[`tie_breaker_${i}`].endsWith("_cut") && !formData.value[`tie_breaker_${i}`].endsWith("_median")) {
        formData.value[`modifier_tie_breaker_${i}`] = 1;
      }
    }

    if(props.isNewTournament) {
      // Create a new tournament
      const response = await api.createTournament(formData.value, timeSelects.value);

      if (response.error.value) {
        fieldErrors.value = response.error.value.response?.data?.error || {};
        toast.add({ severity: 'error', summary: "Error creating tournament", life: 3000 });
      } else {
        toast.add({ severity: 'success', summary: `New tournament created`, life: 3000 });
        router.push(`/tournaments/${response.data.value.tournament.id}`);
      }
    } else {
      const tournamentData = {...formData.value};
      tournamentData.time_initial = scale2seconds(tournamentData.time_initial, timeSelects.value.initial);
      tournamentData.time_per_move = scale2seconds(tournamentData.time_per_move, timeSelects.value.perMove);

      // Update tournament data
      axios.put(`/api/tournaments/${tournamentId.value}`, {
        tournament: tournamentData
      })
      .then(() => {
        router.push(`/tournaments/${tournamentId.value}`)
      })
      .catch((error) => {
        fieldErrors.value = error.response?.data?.error || {};
        toast.add({ severity: 'error', summary: 'Cannot update the tournament data', life: 3000 });
      })
    }
  }

  async function fetchTournamentData() {
    const response = await api.getTournament(tournamentId.value);

    if (response.data.value) {
      formData.value = {
        title: response.data.value.tournament.title,
        date: new Date(response.data.value.tournament.date),
        federation: response.data.value.tournament.federation,
        format: response.data.value.tournament.format,
        max_participants: response.data.value.tournament.max_participants,
        description: response.data.value.tournament.description,
        end_date: response.data.value.tournament.end_date ? new Date(response.data.value.tournament.end_date) : null,
        system: response.data.value.tournament.system,
        max_rounds: response.data.value.tournament.max_rounds,
        address: response.data.value.tournament.address,
        tie_breaker_1: response.data.value.tournament.tie_breaker_1,
        modifier_tie_breaker_1: response.data.value.tournament.modifier_tie_breaker_1,
        tie_breaker_2: response.data.value.tournament.tie_breaker_2,
        modifier_tie_breaker_2: response.data.value.tournament.modifier_tie_breaker_2,
        tie_breaker_3: response.data.value.tournament.tie_breaker_3,
        modifier_tie_breaker_3: response.data.value.tournament.modifier_tie_breaker_3,
        time_initial: response.data.value.tournament.time_initial,
        time_per_move: response.data.value.tournament.time_per_move,
        max_full_byes: response.data.value.tournament.max_full_byes,
        max_half_byes: response.data.value.tournament.max_half_byes,
      };

      if (formData.value.time_initial) {
        const initialTime = seconds2scale(formData.value.time_initial);
        formData.value.time_initial = initialTime.value;
        timeSelects.value.initial = initialTime.scale;
      }
      if (formData.value.time_per_move) {
        const perMoveTime = seconds2scale(formData.value.time_per_move);
        formData.value.time_per_move = perMoveTime.value;
        timeSelects.value.perMove = perMoveTime.scale;
      }

      tournamentId.value = response.data.value.tournament.id
    } else {
      console.error(response.error.value.message);
    }
  }

  onMounted(() => {
    if(!props.isNewTournament) {
      tournamentId.value = router.currentRoute.value.params.id
      fetchTournamentData();
    } else {
      timeSelects.initial = "m";
    }
  })
</script>

<template>
  <form @submit.prevent="handleFormValidation()">
    <div class="flex flex-col gap-3">
      <div class="flex flex-col md:flex-row w-full gap-3 md:gap-2">
        <div class="flex flex-grow flex-col gap-2">
          <label class="text-light-grey" v-t="'tournament.form.titleLabel'"></label>
          <InputText id="username" v-model="formData.title" placeholder="Your tournament name"  autofocus />
          <div v-if="fieldErrors.title" class="text-sm text-red-500">{{ fieldErrors.title[0] }}</div>
        </div>
        <div class="md:w-1/4 flex flex-col gap-2">
          <label for="max-players" class="text-light-grey" v-t="'tournament.form.maxParticipants'"></label>
          <InputText id="max-players" type="number" v-model="formData.max_participants" placeholder="20"  />
          <div v-if="fieldErrors.max_participants" class="text-sm text-red-500">{{ fieldErrors.max_participants[0] }}</div>
        </div>
        <div class="md:w-1/4 flex flex-col gap-2">
          <label for="max-rounds" class="text-light-grey" v-t="'tournament.form.maxRoundsLabel'"></label>
          <InputText @change="maxRoundsUpdateCallback" id="max-rounds" type="number" v-model="formData.max_rounds" placeholder="9" />
          <div v-if="fieldErrors.max_rounds" class="text-sm text-red-500">{{ fieldErrors.max_rounds[0] }}</div>
        </div>
      </div>
      <div class="flex flex-grow flex-col gap-2">
        <label for="address" class="text-light-grey" v-t="'tournament.form.addressLabel'"></label>
        <InputText id="address" v-model="formData.address" class="p-2" placeholder="Your tournament adress"  />
          <div v-if="fieldErrors.address" class="text-sm text-red-500">{{ fieldErrors.address[0] }}</div>
        </div>
      <div class="flex flex-col sm:flex-row gap-2 justify-between">
        <div class="sm:w-1/2 flex flex-col gap-2">
          <label for="date" class="text-light-grey" v-t="'tournament.form.dateLabel'"></label>
          <Calendar id="date" v-model="formData.date" showTime hourFormat="24" class="rounded-md" placeholder="04/06/2024 14:30"  />
          <div v-if="fieldErrors.date" class="text-sm text-red-500">{{ fieldErrors.date[0] }}</div>
        </div>
        <div class="sm:w-1/2 flex flex-col gap-2">
          <label for="end-date" class="text-light-grey" v-t="'tournament.form.endDateLabel'"></label>
          <Calendar id="end-date" v-model="formData.end_date" showTime hourFormat="24" class="rounded-md" placeholder="04/08/2024 14:30"  />
          <div v-if="fieldErrors.end_date" class="text-sm text-red-500">{{ fieldErrors.end_date[0] }}</div>
        </div>
      </div>

      <div class="flex flex-col sm:flex-row w-full gap-3 sm:gap-2">
        <div class="flex flex-col gap-2 grow">
          <label for="federation" class="text-light-grey" v-t="'tournament.form.federation'"></label>
          <select v-model="formData.federation" :disabled="!isNewTournament"
          class="bg-deep-greyblue hover:bg-dark-greyblue text-light-grey rounded-md">
            <option value="friendly">{{ $t('tournament.form.friendly') }}</option>
            <option value="ffe">FFE</option>
          </select>
          <div v-if="fieldErrors.federation" class="text-sm text-red-500">{{ fieldErrors.federation[0] }}</div>
        </div>
        <div class="flex flex-col gap-2 grow">
          <label for="format" class="text-light-grey" v-t="'tournament.form.format'"></label>
          <select v-model="formData.format" id="format"
          class="bg-deep-greyblue hover:bg-dark-greyblue text-light-grey rounded-md"
           >
            <option value="standard">{{ $t('tournament.form.standard') }}</option>
            <option value="rapid">{{ $t('tournament.form.rapid') }}</option>
            <option value="blitz">{{ $t('tournament.form.blitz') }}</option>
          </select>
          <div v-if="fieldErrors.format" class="text-sm text-red-500">{{ fieldErrors.format[0] }}</div>
        </div>
        <div class="flex flex-col gap-2 grow">
          <label for="system" class="text-light-grey" v-t="'tournament.form.system'"></label>
          <select v-model="formData.system"
          id="system" class="bg-deep-greyblue hover:bg-dark-greyblue text-light-grey rounded-md">
            <option v-for="option in pairingSystemOptions" :key="option" :value="option">{{ $t(`tournament.form.${option}`) }}</option>
          </select>
          <div v-if="fieldErrors.system" class="text-sm text-red-500">{{ fieldErrors.system[0] }}</div>
        </div>
      </div>

      <div class="flex flex-col w-full gap-3 sm:gap-2 items-start">
        <div class="flex flex-col gap-2 w-full">
          <label for="tie-breaker-1" class="text-light-grey">{{ $t('tournament.form.tieBreaker') }} 1</label>
          <select id="tie-breaker-1" v-model="formData.tie_breaker_1"
          class="bg-deep-greyblue hover:bg-dark-greyblue text-light-grey rounded-md flex-grow" @change="tieBreakerCallback">
            <option value="">--</option>
            <option v-for="option in tieBreakerOptions" :key="option" :value="option">{{ $t(`tournament.form.${option}`) }}</option>
          </select>
          <div v-if="fieldErrors.tie_breaker_1" class="text-sm text-red-500">{{ fieldErrors.tie_breaker_1[0] }}</div>
          <div class="flex flex-col" v-show="formData.tie_breaker_1?.endsWith('_cut') || formData.tie_breaker_1?.endsWith('_median')">
            <label for="modifier-tie-breaker-1" v-t="'tournament.form.modifier'"></label>
            <InputNumber id="modifier-tie-breaker-1" v-model="formData.modifier_tie_breaker_1" />
            <div v-if="fieldErrors.modifier_tie_breaker_1" class="text-sm text-red-500">{{ fieldErrors.modifier_tie_breaker_1[0] }}</div>
          </div>
        </div>
        <div class="flex flex-col gap-2 w-full" v-show="formData.tie_breaker_1 || formData.tie_breaker_2">
          <label for="tie-breaker-2" class="text-light-grey">{{ $t('tournament.form.tieBreaker') }} 2</label>
          <select id="tie-breaker-2" v-model="formData.tie_breaker_2"
          class="bg-deep-greyblue hover:bg-dark-greyblue text-light-grey rounded-md flex-grow" @change="tieBreakerCallback">
            <option value="">--</option>
            <option v-for="option in tieBreakerOptions" :key="option" :value="option">{{ $t(`tournament.form.${option}`) }}</option>
          </select>
          <div v-if="fieldErrors.tie_breaker_2" class="text-sm text-red-500">{{ fieldErrors.tie_breaker_2[0] }}</div>
          <div class="flex flex-col" v-show="formData.tie_breaker_2?.endsWith('_cut') || formData.tie_breaker_2?.endsWith('_median')">
            <label for="modifier-tie-breaker-2" v-t="'tournament.form.modifier'"></label>
            <InputNumber id="modifier-tie-breaker-2" v-model="formData.modifier_tie_breaker_2" />
            <div v-if="fieldErrors.modifier_tie_breaker_2" class="text-sm text-red-500">{{ fieldErrors.modifier_tie_breaker_2[0] }}</div>
          </div>
        </div>
        <div class="flex flex-col gap-2 w-full" v-show="formData.tie_breaker_2 || formData.tie_breaker_3">
          <label for="tie-breaker-3" class="text-light-grey">{{ $t('tournament.form.tieBreaker') }} 3</label>
          <select id="tie-breaker-3" v-model="formData.tie_breaker_3"
          class="bg-deep-greyblue hover:bg-dark-greyblue text-light-grey rounded-md flex-grow" @change="tieBreakerCallback">
            <option value="">--</option>
            <option v-for="option in tieBreakerOptions" :key="option" :value="option">{{ $t(`tournament.form.${option}`) }}</option>
          </select>
          <div v-if="fieldErrors.tie_breaker_3" class="text-sm text-red-500">{{ fieldErrors.tie_breaker_3[0] }}</div>
          <div class="flex flex-col" v-show="formData.tie_breaker_3?.endsWith('_cut') || formData.tie_breaker_3?.endsWith('_median')">
            <label for="modifier-tie-breaker-3" v-t="'tournament.form.modifier'"></label>
            <InputNumber id="modifier-tie-breaker-3" v-model="formData.modifier_tie_breaker_3" />
            <div v-if="fieldErrors.modifier_tie_breaker_3" class="text-sm text-red-500">{{ fieldErrors.modifier_tie_breaker_3[0] }}</div>
          </div>
        </div>
      </div>

      <div class="w-full flex flex-col gap-3 sm:flex-row">
        <div class="flex-grow">
          <label for="time-initial" class="text-light-grey" v-t="'tournament.form.timeInitial'"></label>
          <div class="flex items-center gap-2 mt-2">
            <div>
              <input type="number" id="time-initial" v-model="formData.time_initial" class="w-20 bg-deep-greyblue rounded" placeholder="15"  />
            </div>
            <select v-model="timeSelects.initial" name="time-initial-select" id="time-initial-select"
            class="bg-deep-greyblue hover:bg-dark-greyblue text-light-grey rounded-md flex-grow">
              <option value="s">{{ $t('tournament.form.seconds') }}</option>
              <option value="m">{{ $t('tournament.form.minutes') }}</option>
              <option value="h">{{ $t('tournament.form.hours') }}</option>
              <option value="d">{{ $t('tournament.form.days') }}</option>
            </select>
          </div>
          <div v-if="fieldErrors.time_initial" class="text-sm text-red-500">{{ fieldErrors.time_initial[0] }}</div>
        </div>
        <div class="flex-grow">
          <label for="time-per-move" class="text-light-grey" v-t="'tournament.form.timePerMove'"></label>
          <div class="flex items-center gap-2 mt-2">
            <div>
              <input type="number" id="time-per-move" v-model="formData.time_per_move" class="w-20 bg-deep-greyblue rounded" placeholder="0"  />
            </div>
              <select v-model="timeSelects.perMove" name="time-per-move-select" id="time-initial-select"
              class="bg-deep-greyblue hover:bg-dark-greyblue text-light-grey rounded-md flex-grow">
              <option value="s">{{ $t('tournament.form.seconds') }}</option>
              <option value="m">{{ $t('tournament.form.minutes') }}</option>
              <option value="h">{{ $t('tournament.form.hours') }}</option>
              <option value="d">{{ $t('tournament.form.days') }}</option>
            </select>
          </div>
          <div v-if="fieldErrors.time_per_move" class="text-sm text-red-500">{{ fieldErrors.time_per_move[0] }}</div>
        </div>
      </div>

      <div class="flex flex-col sm:flex-row gap-2 justify-between">
        <div class="sm:w-1/2 flex flex-col gap-2">
          <label for="max_full_byes" class="text-light-grey" v-t="'tournament.form.maxFullByesLabel'"></label>
          <InputNumber id="max_full_byes" v-model="formData.max_full_byes" class="rounded-md" placeholder="0"  />
          <div v-if="fieldErrors.max_full_byes" class="text-sm text-red-500">{{ fieldErrors.max_full_byes[0] }}</div>
        </div>
        <div class="sm:w-1/2 flex flex-col gap-2">
          <label for="max-half-byes" class="text-light-grey" v-t="'tournament.form.maxHalfByesLabel'"></label>
          <InputNumber id="max-half-byes" v-model="formData.max_half_byes" class="rounded-md" placeholder="0"  />
          <div v-if="fieldErrors.max_half_byes" class="text-sm text-red-500">{{ fieldErrors.max_half_byes[0] }}</div>
        </div>
      </div>

      <div class="flex flex-col gap-2">
        <label for="description" class="text-light-grey" v-t="'tournament.form.descriptionLabel'"></label>
        <Textarea id="description" v-model="formData.description" rows="8" cols="90" class="" placeholder="Your tournament details..." />
        <div v-if="fieldErrors.description" class="text-sm text-red-500">{{ fieldErrors.description[0] }}</div>
      </div>
      <div class="self-end">
        <button type="submit" class="primary-btn" v-t="'confirmBtn'"></button>
      </div>
    </div>
  </form>
</template>
