import { createStore } from 'vuex';
// import axios from './axiosSetup.js';
import axios from 'axios';
import router from '../entrypoints/router.js';
import api from './api.js';
import { checkParticipantLicence } from './utils.js';

const store = createStore({
  state: {
    user: null,
    auth_token: null,
    tournament: {
      user: {
        activationColor: null,
        isAdmin: false,
        referee: null,
      },
      follow: {
        participant: null,
        currentPairing: null,
      },
      isParticipantsLicenceError: false,
      data: null,
      currentRound: null,
    },
    displayFormat: 'normal',
  },
  mutations: {
    setUserInfo(state, payload) {
      localStorage.setItem("followed_participant_id", null)
      state.user = payload.data.user;
      state.auth_token = payload.data.auth_token;
      localStorage.setItem("auth_token", payload.data.auth_token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${payload.data.auth_token}`;
    },
    setUserInfoFromToken(state, payload) {
      localStorage.setItem("followed_participant_id", null)
      state.user = payload.data.user;
      state.auth_token = localStorage.getItem("auth_token");
      axios.defaults.headers.common["Authorization"] = `Bearer ${state.auth_token}`;
    },
    resetUserInfo(state) {
      localStorage.setItem("followed_participant_id", null)
      state.user = null;
      state.auth_token = null;
      localStorage.removeItem("auth_token");
      axios.defaults.headers.common["Authorization"] = null;
    },

    setTournament(state, payload) {
      state.tournament.data = payload;
    },
    setCurrentRound(state, currentRound) {
      state.tournament.currentRound = currentRound;
    },
    setUserAdminRights(state, isAdmin) {
      state.tournament.user.isAdmin = isAdmin;
    },
    setUserRefereeRights(state, refereeRights) {
      state.tournament.user.refereeRights = refereeRights;
    },

    setUserParticipant(state, participant) {
      state.tournament.follow.participant = participant
    },
    setUserCurrentPairing(state, pairing) {
      state.tournament.follow.currentPairing = pairing
    },

    setDisplayFormat(state, displayFormat) {
      state.displayFormat = displayFormat
    },

    setParticipantsLicenceError(state, value) {
      state.tournament.isParticipantsLicenceError = value;
    }
  },

  actions: {
    loginUser({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/users/sign_in', payload)
          .then((response) => {
            commit('setUserInfo', response);
            api.subscribeWebpush(response.data.auth_token, null)
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    logoutUser({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/users/sign_out')
          .then(() => {
            commit('resetUserInfo');
            router.push('/login')
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    loginUserWithToken({ commit }, payload) {
      const config = {
        headers: {
          Authorization: `Bearer ${payload.auth_token}`,
        }
      }
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/memberdata`, config)
          .then((response) => {
            commit('setUserInfoFromToken', response);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateTournamentContext({ state, commit }, tournament) {
      const currentRound = tournament.rounds.length > 0 ? tournament.rounds.sort((a, b) => a.index - b.index).filter(round => round.status !== "draft").slice(-1)[0] : null;
      const userReferee = tournament.referees.find((referee) => referee.user.id === store.getters.getUser?.id )
      let userParticipant = null
      if (store.getters.isAuthenticated) {
        userParticipant = tournament.participants.find(participant => participant.user_id === store.getters.getUser.id)
      } else if (localStorage.getItem("followed_participant_id")) {
        userParticipant = tournament.participants.find(participant => participant.id === localStorage.getItem("followed_participant_id"))
      }
      const userPairing = Boolean(currentRound && userParticipant) ? currentRound.pairings.find(pairing => pairing.black_player_id === userParticipant.id || pairing.white_player_id === userParticipant.id) : null;

      const isReferee = tournament.admin?.id === store.getters.getUser?.id || !!userReferee
      if (isReferee) {
        const isLicenceError = !!tournament.participants.find(participant => checkParticipantLicence(tournament, participant) === false)
        commit('setParticipantsLicenceError', isLicenceError);
      }

      commit('setTournament', tournament);
      commit('setCurrentRound', currentRound);
      commit('setUserRefereeRights', userReferee);
      commit('setUserParticipant', userParticipant);
      commit('setUserCurrentPairing', userPairing);
      commit('setUserAdminRights', (state.user && tournament.admin?.id === state.user.id))
    },
    followParticipant({ state, commit }, participant) {
      const userPairing = participant && Boolean(state.tournament.currentRound && participant) ? state.tournament.currentRound.pairings.find(pairing => pairing.black_player_id === participant.id || pairing.white_player_id === participant.id) : null;
      participant ? localStorage.setItem("followed_participant_id",  participant.id) : localStorage.removeItem("followed_participant_id")

      commit('setUserParticipant', participant);
      commit('setUserCurrentPairing', userPairing);
    }
  },

  getters: {
    isAuthenticated: state => !(state.auth_token == null || state.auth_token == JSON.stringify(null)),
    getUser: state => state.user,
    getAuthToken: state => state.auth_token,
    getTournament: state => state.tournament.data,
    getCurrentRound: state => state.tournament.currentRound,
    getUserReferee: state => state.tournament.user.refereeRights,
    getUserParticipant: state => state.tournament.follow.participant,
    getUserCurrentPairing: state => state.tournament.follow.currentPairing,
    getDisplayFormat: state => state.displayFormat,
    isUserAdmin: state => state.tournament.user.isAdmin,
    isParticipantsLicenceError: state => state.tournament.isParticipantsLicenceError,
  }
})

export default store;
